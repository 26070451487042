import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Header from '../layout/header2'
import Footer from '../layout/footer2'
import HomeSlider from '../element/homeslider1'
import {homeSliderContent2} from '../element/SliderContent'
import ScrollToTop from '../element/scrollToTop';

// import '../../css/skin/skin-2.css';


class Index2 extends Component {

    
    render() {
        function convertToBase64(){
            var selectedFile = document.getElementById("inputFile").files;
            if (selectedFile.length > 0) {
                var fileToLoad = selectedFile[0];
          
                var fileReader = new FileReader();
                var base64;
                fileReader.onload = function(fileLoadedEvent) {
                    base64 = fileLoadedEvent.target.result;
            
                    console.log(base64);
                };
                // Convert data to base64
                fileReader.readAsDataURL(fileToLoad);
            }
        }
        return (
            <div className="skin-2">
            
           

                {/* <HomeSlider 
                data={homeSliderContent2}
                contentWrapperCls="sliderStyle2"
                btnText="Read More"
                /> */}
                        {/* <div className="content-block">
                            <div className="section-full content-inner bg-white">
                                <div className="container">
                                    <div className="row align-items-center">
                                        <div className="col-lg-6 col-md-12 m-b30">
                                            <div className="our-story">
                                                <span>Çalışma Var</span>
                                                <h2 className="title">Anlayışınız İçin <br/>Teşekkür Ederiz <br/></h2>
                                            
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-12 m-b30 our-story-thum">
                                            <img src={require("../../images/about/pic6.jpg")} className="radius-sm" alt=""/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                <div className="page-content bg-white">
                {/* <input id="inputFile" type="file" onChange={convertToBase64} /> */}

                    <div className="content-block">
                        <div className="section-full">
                            <div className="row spno about-industry">
                                <div className="col-lg-8 wow fadeIn" data-wow-duration="2s" data-wow-delay="0.2s">
                                    <div className="dlab-post-media dlab-img-effect zoom"> 
                                        <img src={require("../../images/factory-671598_1920.jpg")} alt="" className="img-cover"/>
                                    </div>
                                </div>
                                <div className="col-lg-4 bg-white wow fadeIn" data-wow-duration="2s" data-wow-delay="0.4s">
                                    <div className="service-box style2">
                                        <div>
                                            <h2 className="title text-black"><span>Isıl </span> <br/>İşlem</h2>
                                            {/* <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p> */}
                                            <Link to="/hakkimizda" className="site-button outline outline-2 btnhover11">Hakkımızda</Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 wow fadeIn" data-wow-duration="2s" data-wow-delay="0.6s">
                                    <div className="dlab-post-media dlab-img-effect zoom"> 
                                        <img src={require("../../images/production-process-details.jpg")} alt="" className="img-cover"/>
                                    </div>
                                </div>
                                <div className="col-lg-4 bg-secondry text-white wow fadeIn" data-wow-duration="2s" data-wow-delay="0.8s">
                                    <div className="service-box style2">
                                        <div>
                                            <h2 className="title"><span>Talaşlı  </span> <br/>İmalat</h2>
                                            {/* <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p> */}
                                            <Link to="/hakkimizda" className="site-button outline outline-2 btnhover11">Hakkımızda</Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 wow fadeIn" data-wow-duration="2s" data-wow-delay="0.2s">
                                    <div className="dlab-post-media dlab-img-effect zoom"> 
                                        <img src={require("../../images/industry-3058033_1920.jpg")} alt="" style={{ maxHeight: "300px" }} className="img-cover"/>
                                    </div>
                                </div>
                                <div className="col-lg-4 bg-red  text-white wow fadeIn" data-wow-duration="2s" data-wow-delay="0.4s">
                                    <div className="service-box style2">
                                        <div>
                                            <h2 className="title"><span>Ardıl   </span> <br/>İşlem</h2>
                                            {/* <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p> */}
                                            <Link to="/hakkimizda" className="site-button outline outline-2 btnhover11">Hakkımızda</Link>                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 wow fadeIn" data-wow-duration="2s" data-wow-delay="0.6s">
                                    <div className="dlab-post-media dlab-img-effect zoom"> 
                                        <img src={require("../../images/portrait-young-worker-hard-hat-large-waste-recycling-factory.jpg")} alt="" className="img-cover"/>
                                    </div>
                                </div>
                                <div className="col-lg-4 bg-red text-white wow fadeIn" data-wow-duration="2s" data-wow-delay="0.8s">
                                    <div className="service-box style2">
                                        <div>
                                            <h2 className="title"><span>Bitmiş Ürün  </span> <br/>Hizmeti</h2>
                                            {/* <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p> */}
                                            <Link to="/hakkimizda" className="site-button outline outline-2 btnhover11">Hakkımızda</Link>                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 bg-white wow fadeIn" data-wow-duration="2s" data-wow-delay="0.2s">
                                    <div className="service-box style2">
                                        <div>
                                            <h2 className="title text-black"><span>Danışmanlık  </span> <br/>Hizmetleri</h2>
                                            {/* <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p> */}
                                            <Link to="/hakkimizda" className="site-button outline outline-2 btnhover11">Hakkımızda</Link>                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-8 wow fadeIn" data-wow-duration="2s" data-wow-delay="0.4s">
                                    <div className="dlab-post-media dlab-img-effect zoom"> 
                                        <img src={require("../../images/our-work/oilgas/pic4.jpg")} alt="" className="img-cover"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>                
                <Footer/>  
                <ScrollToTop className="style1 radius"/>              
            </div>
        )
    }
}
export default Index2;