import React, { Component,useRef,useState } from "react";
import { Link } from "react-router-dom";
import Header from "../layout/header2";
import Footer from "../layout/footer2";
import emailjs from "@emailjs/browser";
import axios from 'axios';

const bg = require("../../images/banner/bnr1.jpg");
const MyEmail = () => {
  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();

    const formData = new FormData(form.current);
    const name = formData.get("dzName");
    const phone = formData.get("dzOther[Phone]");
    const email = formData.get("dzEmail");
    const message = formData.get("dzMessage");


    emailjs.send('service_hr1s84g', 'template_j863ps4', {
      to_email: 'info@norsal.com.tr', 
      message: message, from_name:name ,phone:phone , email:email
    },'q9EpdcLZDBI9MD-Wx')
    .then(
      (result) => {
        console.log(result.text);
      },
      (error) => {
        console.log(error.text);
      }
    );
  e.target.reset();
  };
  
  function FileUpload() {
    const [selectedFile, setSelectedFile] = useState(null);
  
    const fileSelectedHandler = (event) => {
      setSelectedFile(event.target.files[0]);
    };
  
    const fileUploadHandler = () => {
      const formData = new FormData();
      formData.append('file', selectedFile);
  
      // Sunucuya dosyayı yükleme
      axios.post('/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then(response => {
        console.log(response.data);
      }).catch(error => {
        console.error('Dosya yükleme hatası:', error);
      });
    };
  
    return (
      <div>
        <input type="file" onChange={fileSelectedHandler} />
        <button onClick={fileUploadHandler}>Dosyayı Yükle</button>
      </div>
    );
  }
  return (
    <form
    className="p-a30 bg-gray clearfix radius-sm"
    data-wow-delay="0.2s"
    ref={form} onSubmit={sendEmail}
  >
   <h3 className="m-b10">Bize Mesaj Gönder</h3>
    <div className="row">
      <div className="col-lg-12 col-md-12">
        <div className="form-group">
          <div className="input-group">
          
            <input
              name="dzName"
              type="text"
              required
              className="form-control"
              placeholder="İsim Soyisim"
            />
          </div>
        </div>
      </div>
      <div className="col-lg-12 col-md-12">
        <div className="form-group">
          <div className="input-group">
        
            <input
              name="dzOther[Phone]"
              type="text"
              required
              className="form-control"
              placeholder="Telefon"
            />
          </div>
        </div>
      </div>
      <div className="col-lg-12 col-md-12">
        <div className="form-group">
          <div className="input-group">
          
            <input
              name="dzEmail"
              type="email"
              className="form-control"
              required
              placeholder="E-mail"
            />
          </div>
        </div>
      </div>
      <div className="col-lg-12 col-md-12">
        <div className="form-group">
          <div className="input-group">
          
            <textarea
              name="dzMessage"
              rows="4"
              className="form-control"
              required
              placeholder="İletiniz...."
            ></textarea>
          </div>
        </div>
      </div>
      <div className="col-lg-12 col-md-12">
        <button
          name="submit"
          type="submit"
          value="Submit"
         className="site-button "
        >
          {" "}
          <span>Gönder</span>{" "}
        </button>
      </div>
    </div>
  </form>
  );
};


class Contact3 extends Component {
  render() {
    return (
      <>
      
        <div className="page-content bg-white">
          <div
            className="dlab-bnr-inr overlay-black-middle bg-pt"
            style={{ backgroundImage: "url(" + bg + ")" }}
          >
            <div className="container">
              <div className="dlab-bnr-inr-entry">
                <h1 className="text-white">İletişim</h1>
                <div className="breadcrumb-row">
                  <ul className="list-inline">
                    <li>
                      <Link to="/index">Anasayfa</Link>
                    </li>
                    <li>Bize Ulaş</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div className="section-full content-inner bg-white contact-style-1">
            <div className="container">
              <div className="row">
                <div className="col-lg-4 col-md-6 d-flex m-b30">
                  <div className="p-a30 border contact-area border-1 align-self-stretch radius-sm">
                    
                    <ul className="no-margin">
                      <li className="icon-bx-wraper left m-b30">
                        <div className="icon-bx-xs border-1">
                          {" "}
                          <Link to="/#" className="icon-cell">
                            <i className="ti-location-pin"></i>
                          </Link>{" "}
                        </div>
                        <div className="icon-content">
                          <h6 className="text-uppercase m-tb0 dlab-tilte">
                            Adres:
                          </h6>
                          <p>
                          Balçık Mah. 3239 Sok. No:23
                          Kocaeli-Gebze, Türkiye
                          </p>
                        </div>
                      </li>
                      <li className="icon-bx-wraper left  m-b30">
                        <div className="icon-bx-xs border-1">
                          {" "}
                          <Link to="/#" className="icon-cell">
                            <i className="ti-email"></i>
                          </Link>{" "}
                        </div>
                        <div className="icon-content">
                          <h6 className="text-uppercase m-tb0 dlab-tilte">
                            Email:
                          </h6>
                          <p>fatihuslu@norsal.com.tr</p>
                        </div>
                      </li>
                      <li className="icon-bx-wraper left">
                        <div className="icon-bx-xs border-1">
                          {" "}
                          <Link to="/#" className="icon-cell">
                            <i className="ti-mobile"></i>
                          </Link>{" "}
                        </div>
                        <div className="icon-content">
                          <h6 className="text-uppercase m-tb0 dlab-tilte">
                            PHONE
                          </h6>
                          <p>+90 (532) 164 42 51 </p>
                        </div>
                      </li>
                    </ul>
                    <div className="m-t20">
                      <ul className="dlab-social-icon border dlab-social-icon-lg">
                     
                        <li>
                        <a
                            href="https://www.linkedin.com/company/norsal-metalurji/"
                            className="fa fa-linkedin bg-primary"
                            target="_blank"
                            rel="noopener noreferrer"
                        ></a>
                        </li>
                        <li>
                            <a
                                href="https://www.instagram.com/norsal_metalurji/"
                                className="fa fa-instagram bg-primary"
                                target="_blank"
                                rel="noopener noreferrer"
                            ></a>
                       
                        </li>
                     
                      </ul>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 col-md-6  mb-4 m-b30 mb-md-0">
                  {/* <div className="p-a30 bg-gray clearfix radius-sm">
                    <h3 className="m-b10">Bize Mesaj Gönder</h3>
                    <div className="dzFormMsg"></div>
                    <form
                      method="post"
                      className="dzForm"
                      action="script/contact.php"
                    >
                      <input type="hidden" value="Contact" name="dzToDo" />
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="form-group">
                            <div className="input-group">
                              <input
                                name="dzName"
                                type="text"
                                required
                                className="form-control"
                                placeholder="İsiminiz"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="form-group">
                            <div className="input-group">
                              <input
                                name="dzEmail"
                                type="email"
                                className="form-control"
                                required
                                placeholder="Email"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="form-group">
                            <div className="input-group">
                              <textarea
                                name="dzMessage"
                                rows="4"
                                className="form-control"
                                required
                                placeholder="Mesajınız..."
                              ></textarea>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="form-group">
                            <div className="input-group">
                              <div
                                className="g-recaptcha"
                                data-sitekey="6LefsVUUAAAAADBPsLZzsNnETChealv6PYGzv3ZN"
                                data-callback="verifyRecaptchaCallback"
                                data-expired-callback="expiredRecaptchaCallback"
                              ></div>
                              <input
                                className="form-control d-none"
                                style={{ display: "none" }}
                                data-recaptcha="true"
                                required
                                data-error="Please complete the Captcha"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <button
                            name="submit"
                            type="submit"
                            value="Submit"
                            className="site-button "
                          >
                            {" "}
                            <span>Gönder</span>{" "}
                          </button>
                        </div>
                      </div>
                    </form>
                  </div> */}
                  <MyEmail/>
                </div>

                <div className="col-lg-4 d-flex m-b30">
                  <iframe
                    title="map2"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d533.3470021909025!2d29.417729303342366!3d40.870420161234534!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14cad9b50be98275%3A0xdbf95460cc5b2531!2sNorsal%20Metalurji!5e0!3m2!1str!2str!4v1719672283448!5m2!1str!2str"
                    className="align-self-stretch radius-sm"
                    style={{ border: "0", width: "100%", minHeight: "100%" }}
                    allowfullscreen
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}
export default Contact3;
