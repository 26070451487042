import React, { Component } from "react";
import { Link } from "react-router-dom";
import Header from "../layout/header2";
import Footer from "../layout/footer2";
import Testimonial1 from "../element/testimonial1";

var bnr3 = require("./../../images/banner/bnr5.jpg");
var bg1 = require("./../../images/background/bg-video.png");
var bg2 = require("./../../images/background/bg1.jpg");

class About1 extends Component {
  render() {
    return (
      <>
     

        <div className="page-content bg-white">
          <div
            className="dlab-bnr-inr overlay-black-middle text-center bg-pt"
            style={{ backgroundImage: "url(" + bnr3 + ")" }}
          >
            <div className="container">
              <div className="dlab-bnr-inr-entry align-m text-center">
                <h1 className="text-white">Hakkımızda</h1>
                <div className="breadcrumb-row">
                  <ul className="list-inline">
                    <li>
                      <Link to={"/"}>Anasayfa</Link>
                    </li>
                    <li>Hakkımızda</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="content-block">
            <div
              className="section-full content-inner bg-white video-section"
              style={{ backgroundImage: "url(" + bg1 + ")" }}
            >
              <div className="container">
                <div className="section-content">
                  <div className="row d-flex">
                    <div className="col-lg-6 col-md-12 m-b30">
                      <div className="video-bx">
                        <img
                          src={require("../../images/about/pic5.jpg")}
                          alt="Signature"
                        />
                        <div className="video-play-icon">
                          {/* <a href="https://www.youtube.com/watch?v=_FRZVScwggM" className="popup-youtube video bg-primary"><i className="fa fa-play"></i></a> */}
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12 m-b30 align-self-center video-infobx">
                      <div className="content-bx1">
                        <h2 className="m-b15 title">
                          Norsal Metalurji
                          <br />
                          <span className="text-primary"> Biz Kimiz</span>
                        </h2>
                        <p className="m-b30">
                          Norsal Metalurji olarak, metalurji ve imalat
                          sektöründe güçlü ve deneyimli kadromuzla hizmet
                          vermekteyiz. Alüminyum ısıl işlem, talaşlı imalat ve
                          ardıl işlem ile bitmiş ürün hizmetlerinde kapsamlı
                          çözümler sunmaktayız. Kalite ve müşteri memnuniyetine
                          olan bağlılığımız, bizi güvenilir ve yüksek
                          performanslı mühendislik hizmetleri arayan işletmeler
                          için tercih edilen bir ortak haline getirmiştir.
                          Dinamik ekibimiz ve modern teknolojilerimizle sektörde
                          fark yaratmayı hedefliyoruz. Norsal Metalurji olarak,
                          müşterilerimizin ihtiyaçlarına özel çözümler sunarak
                          onların beklentilerini aşmayı amaçlıyoruz.
                        </p>
                        {/* <img src={require('../../images/sign.png')} width="200" alt="" />
                                                <h4 className="m-b0">Jone Doe</h4>
                                                <span className="font-14">Company Director</span> */}
                      </div>
                      <div className="content-bx1">
                        <h2 className="m-b15 title">
                          Norsal Metalurji olarak
                          <br />
                          <span className="text-primary"> Misyonumuz,</span>
                        </h2>
                        <p className="m-b30">
                          metalurji ve imalat sektöründe müşterilerimize
                          yenilikçi ve yüksek kaliteli çözümler sunarak onların
                          başarılarına katkıda bulunmaktır. Sürekli olarak
                          süreçlerimizi ve teknolojilerimizi geliştirerek,
                          sektörde öncü olmayı hedefliyoruz. Güçlü iş
                          ortaklarımız ve uzman ekibimizle birlikte, her projede
                          mükemmelliği ve müşteri memnuniyetini en üst düzeyde
                          tutuyoruz. Sürdürülebilir üretim yöntemleriyle çevreye
                          duyarlılığı da gözeterek, müşterilerimize değer
                          katmayı ve sektörde güvenilir bir konum elde etmeyi
                          amaçlıyoruz. Amacımız, üstün kalite standartlarında
                          güvenilirlik ve olağanüstü hizmet sunarak uzun vadeli
                          iş ilişkileri kurmaktır.
                        </p>
                        {/* <img src={require('../../images/sign.png')} width="200" alt="" />
                                                <h4 className="m-b0">Jone Doe</h4>
                                                <span className="font-14">Company Director</span> */}
                      </div>
                      <div className="content-bx1">
                        <h2 className="m-b15 title">
                          Norsal Metalurji olarak
                          <br />
                          <span className="text-primary"> Vizyonumuz,</span>
                        </h2>
                        <p className="m-b30">
                          Norsal Metalurji, İmalat ve Mühendislik Hizmetlerinde
                          Ltd Şti olarak vizyonumuz, metalurji, imalat ve
                          mühendislik hizmetleri alanında öncü bir konum elde
                          etmek ve sektördeki en yenilikçi çözümleri sunmak için
                          sürekli olarak ileriye bakmaktır. Güçlü bir teknoloji
                          altyapısı ve uzman kadromuzla, global ölçekte tanınan
                          ve tercih edilen bir marka olmayı hedefliyoruz.
                          Müşteri odaklı yaklaşımımız ve sürdürülebilir iş
                          uygulamalarımızla, sektörde kalıcı bir etki yaratmak
                          ve müşterilerimize değer katmak için çalışıyoruz.
                        </p>
                        {/* <img src={require('../../images/sign.png')} width="200" alt="" />
                                                <h4 className="m-b0">Jone Doe</h4>
                                                <span className="font-14">Company Director</span> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </>
    );
  }
}

export default About1;
